import { ExamProject, ExamRoom, School } from './interface-index';
import { uuid } from '@/tool/index';

export enum ContractType {
  unknown = "",
  trial = "trial",//试用版
  beta = "beta",//测试版
  demo = "demo",//演示版
  specialoffer = "specialoffer",//特惠普及版
  standard = "standard",//标准版
  plus = "plus"//旗舰版
}

export enum StudentExamPaperRule {
  unknow = '',
  examStudentSeatNoFirst = 'examStudentSeatNoFirst',
  localSeatNoFirst = 'localSeatNoFirst',
  examAccountFirst = 'examAccountFirst',
  localIpFirst = 'localIpFirst',
  seatNo = 'seatNo',
  ip = 'ip',
  examStudentSeatNo = 'examStudentSeatNo',
  examAccount = 'examAccount'
}

export class ExamSchool implements School {
  standardExamStudents: number = 0;
  schoolId: string = "";
  schoolName: string = "";
  primarySchool: boolean = false;
  middleSchool: boolean = false;
  highSchool: boolean = false;
  address: string = "";
  contactPerson: string = "";
  contactPersonPhone: string = "";
  contactPersonEmail: string = "";
  contactPerson02: string = "";
  contactPersonPhone02: string = "";
  contactPersonEmail02: string = "";
  areaId: string = "";
  areaName: string = "";
  registrationCode: string = "";
  registered: boolean = false;
  schoolContractId: string = "";
  schoolContractType: ContractType = ContractType.unknown;
  schoolContractStartDate!: Date;
  schoolContractEndDate!: Date;
  classRoomLimitNumber: number = 0;
  examRoomLimitNumber: number = 0;
  teacherAccountLimitNumber: number = 0;
  str03: string = "";
}



export const schoolContractTypeOptions: { label: string, value: string }[] = [
  {
    label: "试用版",
    value: ContractType.trial,
  },
  {
    label: "测试版",
    value: ContractType.beta,
  },
  {
    label: "演示版",
    value: ContractType.demo,
  },
  {
    label: "特惠普及版",
    value: ContractType.specialoffer,
  },
  {
    label: "标准版",
    value: ContractType.standard,
  },
  {
    label: "旗舰版",
    value: ContractType.plus,
  },
];

export class SchoolContract {
  schoolContractId: string = "";
  schoolContractNo: string = "";
  schoolContractType: ContractType = ContractType.unknown;
  schoolContractStartDate!: Date;
  schoolContractEndDate!: Date;
  contractContent: string = "";
  schoolId: string = "";
  resourceTemplateId: string = "";
}

export class SchoolContractItem extends SchoolContract {
  active: boolean = false;
  schoolName: string = '';
  resourceTemplateName: string = '';
  returnedPercent: number = 0;
  sharedPercent: number = 0;
}

export class SchoolContractReturned {
  schoolContractReturnedId: string = '';
  schoolContractId: string = "";
  returnedPercent: number = 0;
  paymentDate: Date = new Date();
  rem: string = '';
}

export class SchoolContractShared {
  schoolContractSharedId: string = '';
  schoolContractId: string = "";
  sharedPercent: number = 0;
  paymentDate: Date = new Date();
  rem: string = '';
}

export class ExamPaper {
  examProjectId: string = "";
  examPaperId: string = "";
  examPaperNo: string = "";
  examPaperName: string = "";
  examPaperUnzipCode: string = "";
  examPaperFileName: string = "";
  sourceType: {
    paperFileName: string,
    validateCodeFileName: string
  } = {
      paperFileName: "",
      validateCodeFileName: ""
    };
  examStartTime: string = "";
  examEndTime: string = "";
  examPaperFileUrl: string = "";
  examStartTimeStr: string = "";
  examEndTimeStr: string = "";
  fileHashCode: string = "";
  answerFileCount: number = 0;
  public get examTimeLabel(): string {
    if ((this.examStartTimeStr == "1971-01-01 00:00:00") && (this.examEndTimeStr == "9999-12-30 00:00:00")) {
      return "不限"
    }
    let d1: Date = new Date(this.examStartTime);
    let d2: Date = new Date(this.examEndTime);

    const s1 = `${d1.getHours()}:${d1.getMinutes()}`;
    const s2 = `${d2.getHours()}:${d2.getMinutes()}`;

    const x1 = "7:0";
    const x2 = "12:29";
    const x3 = "12:30";
    const x4 = "21:30";

    if (d1.getDate() == d2.getDate()) {
      if (s1 == x1 && s2 == x2) {
        return `${d1.getDate()}日上午`
      } else if (s1 == x3 && s2 == x4) {
        return `${d1.getDate()}日下午`
      } else if (s1 == x1 && s2 == x4) {
        return `${d1.getDate()}日全天`
      } else {
        return `${d1.getDate()}日 ${s1}-${s2}`
      }
    }
    //
    if (s1 == x1 && s2 == x4) {
      return `${d1.getDate()}-${d2.getDate()}日全天`
    }
    //
    const ss1 = (s1 == x1) ? "上午" : (s1 == x3) ? "下午" : s1;
    const ss2 = (s2 == x2) ? "上午" : (s2 == x4) ? "下午" : s2;
    return `${d1.getDate()}日${ss1}-${d2.getDate()}日${ss2}`
  }
}

export class ExamProjectPro implements ExamProject {
  public examProjectId = uuid()
  public examProjectName = ''
  public examType = ''
  public examState = ''
  public examProjectNo = ''
  public examStartTime = ''
  public examEndTime = ''
  public examStartTimeStr = ''
  public examEndTimeStr = ''
  public examParams = {
    allowStudentSeatNoDiffLocalSeatNoIfy: true,
    allowLateStart: true,
    allowLateStartMaxMinutes: 5,
    allowAdvanceStop: false,
    allowAdvanceStopMaxMinutes: 0,
    showRaiseHand: false,
    lockWin: true,
    fullScreen: true,
    showExamNotice: false,
    showSeatNumber: true,
    skipRecordingTest: false,
    strictCheckAnswerPackage: true,
    skipEnvironmentCheck: true,
    studentExamPaperRule: StudentExamPaperRule.unknow,//'ip'
    autoExamTimes: false
  }
  examPapers: ExamPaper[] = [];
  examSchools: ExamSchool[] = [];// ExamSchool[]
  examRooms: ExamRoom[] = [];
}

export class ExamStudent {
  examStudentId: string = uuid();
  examStudentName: string = "";
  examAccount: string = "";
  schoolName: string = "";
  schoolId: string = "";
  className: string = "";
  sex: string = "";
  photoFileName: string = "";
  photoFileUrl: string = "";
  examProjectId: string = "";
  examTimeId: string = "";
  examRoomId: string = "";
  ip: string = "";
  seatNo: string = "";
  examPaperId: string = "";
  studentId: string = "";
  examState: string = "";
  examSchoolId: string = "";
}

export class User {
  userId: string = "";
  username: string = "";
  name: string = "";
  password: string = "";
  psdLastChgTime: Date = new Date();
  psdVersion: string = "";
  psdValidDays: number = 180;
  roles: string[] = []
}

export class SetupFile {
  fileId: string = uuid();
  fileHashCode: string = "";
  productVersion: string = "";
  readme: string = "";
  releaseNotes: string = "";
}

export class TestPaperGroup {
  termNo: string = "";
  term: string = "";
  termNo02: string = "";
  term02: string = "";
  gradeNo: string = "";
  grade: string = "";
  testPaperCount: number = 0;
  useAuthCount: number = 0;
}

export class TestPaperGroupGradeOption {
  gradeNo: string = "";
  grade: string = "";
  middleSchool: boolean = false;
  highSchool: boolean = false;
}

export class TestPaper {
  testPaperId: string = "";
  testPaperName: string = "";
  testPaperNo: string = "";
  termNo: string = "";
  term: string = "";
  termNo02: string = "";
  term02: string = "";
  gradeNo: string = "";
  grade: string = "";
  testPaperFileName: string = "";
  testPaperFileUrl: string = "";
  testPaperFileHashCode: string = "";
  str01: string = "";
  str02: string = "";
}

export class TestPaperItem extends TestPaper {
  paperFileInfo: {
    hashCode: string
  } = { hashCode: "" }
}

export class ResourceTemplate {
  resourceTemplateId: string = uuid();
  resourceTemplateName: string = "";
}

export class ResourceTemplateProfile extends ResourceTemplate {
  testPaperList: {
    resourceTemplateId?: string,
    testPaperId: string,
    testPaperName?: string,
    grade?: string,
    term?: string,
    term02?: string
  }[] = []
}

export class ResourceTemplateTestPaperUseAuth {
  schoolId!: string;
  testPaperId!: string;
  resourceTemplateId!: string
}

export class SchoolSchoolGroup {
  schoolSchoolGroupId: string = "";
  schoolGroupId: string = "";
  schoolId: string = "";
  schoolName: string = "";
  primarySchool: Boolean = false;
  middleSchool: Boolean = false;
  highSchool: Boolean = false;
  areaId: string = "";
  areaName: string = "";
}

export class SchoolGroup {
  schoolGroupId: string = "";
  schoolGroupName: string = "";
  userId: string = "";
  schools: SchoolSchoolGroup[] = []
}

